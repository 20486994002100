@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Manuale:400,500,600,700);
/* Custom css */

/* font-family: 'Roboto', sans-serif;
   font-family: 'Manuale', serif; */


a:focus, a:hover{
	outline: none;
}
a{
  transition: all 0.5s ease-in-out;
}
a:hover{
    text-decoration: none;
}
a, a:hover, a:focus, button, button:hover,  button:focus{
    outline: none;
    box-shadow: none;
}
.tm-h2 {
    font-size: 37px;
    line-height: 50px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    color: #771b17;
}


*,ul{
	margin: 0;
	padding: 0;
}
*,li{
	list-style: none;
}

body{	
	font-size: 14px;
	line-height: 24px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}
h1 {
    font-size: 52px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    color: #23ef2c;
    margin: 0;
    line-height: 60px;
}
h2 {
    font-size: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-transform: capitalize;
    color: #23ef2c;
    margin: 0;
    line-height: 50px;
}
h3 {
    font-size: 32px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #23ef2c;
    margin: 0;
    line-height: 24px;
}
h4 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #23ef2c;
    margin: 0;
    line-height: 40px;
}
h5 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #23ef2c;
    margin: 0;
    line-height: 40px;
}
h6 {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin: 0;
    line-height: 24px;
    color: #1d283f;
}
p {
    font-size: 15px;
    color: #7b7f86;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    font-weight: 400;
}

/* menu bar */

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-default .navbar-toggle {
    border-color: #258aff;
    background: #258aff;
    outline: none;
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.navbar-header{
	display:none;
}
.border-col {
    border: 1px #23ef2c solid;
    border-radius: 15px;
    margin-bottom: 10px;
    padding-bottom: 15px;
}
.border-col h4 {
    padding: 0px 15px;
    border-bottom: 1px #23ef2c solid;
    line-height: 32px;
}
.box-col h5{
    text-align: center;
}
.from-col input {
    width: 35px;
    height: 40px;
    border-radius: 0;
    margin: 0 3px;
    text-align: center;
}
.from-col label {
    margin: 0;
    color: #23ef2c;
    font-weight: 500;
    font-size: 16px;
    vertical-align: bottom;
}
.box-col {
    text-align: center;
    /*border-bottom: 1px #23ef2c solid;*/
}

.box-col p {
    padding: 0 20px;
    color: #23ef2c;
    text-align: left;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    border-bottom: 1px #23ef2c solid;
    padding-bottom: 10px;
    line-height: 20px;
}
.box-col-list h5 {
    text-align: left;
    padding: 0 5px;
}
.box-col-list h6 {
    text-align: left;
    padding: 0 20px;
    color: #23ef2c;
    font-weight: 500;
    padding-bottom: 5px;
    padding-top: 5px;
}
.box-col-list{
    text-align: left;
}
.box-col-list .from-col{
    padding: 0 20px;
}
.divider-form{
    color: #23ef2c;
    font-size: 50px;
    font-weight: 200;
}
.form-list-exam {
    width: 35%;
    display: inline-block;
}
.form-list-exam-2 {
    width: 22%;
    text-align: center;
}
.box-col-list {
    padding-top: 4px;
}
.box-col-list .from-col{
    padding-top: 10px;
}
.from-col-date{
    width: 100%;
}
.from-col-date input {
    width: 42%;
}
.from-col-date .time-col {
    width: 45px;
    height: 40px;
    margin: 0 3px;
    text-align: center;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    padding: 7px;
    color: #23ef2c;
    font-weight: 500;
    font-size: 16px;
}
.from-col-list-data input {
    width: 100%;
}
.form-row-inline-custom label {
    padding: 0;
    width: auto;
    display: inline-block;
}
.form-row-inline-custom  input {
    width: 35%;
    display: inline-block;
}
.phone-col-custom input {
    width: 65%;
}
.from-col-list-data form input {
    border: none;
    border-bottom: 1px #ddd solid;
}
.form-row-inline-custom{
    margin-bottom: 10px;
}
.form-row-inline-custom-1 input{
    width: 65%;
}
.textarea-text label{
    padding-bottom: 10px;
}
.note-text {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    display: inline-block;
    color: #23ef2c;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    padding-top: 10px;
}
.textarea-text{
    margin-bottom: 5px;
}
.border-col-1 h4 {
    padding: 0;
    font-size: 14px;
    text-align: center;
}
.box-width-samll{
    width: 30px;
}
.right-table-box .table td, .right-table-box .table th {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    vertical-align: middle;
    font-weight: 600;
    font-size: 15px;
}
.input-box-with-dot input {
    width: 35px;
    height: 35px;
    border-radius: 0;
    margin: 0 2px;
    text-align: center;
    display: inline-block;
}
.dot-from {
    width: 8px;
    height: 8px;
    background: #23ef2c;
    display: inline-block;
    border-radius: 50%;
}
.right-table-box .table thead tr th{
    color: #23ef2c;
}
.box-width-samll{
    text-align: center;
    color: #23ef2c;
}
.right-col-table table.table{
    margin-bottom: 0;
}
.border-col-1{
    padding-bottom: 0;
}
.right-col-table table.table td, .right-col-table table.table th {
    border-top: 1px solid #23ef2c;
}
.right-col-table table.table .border-right {
    border-right: 1px solid #23ef2c!important;
}

.right-table-box{
    display: -webkit-flex;
    display: flex;
}
.table thead th {
    border-bottom: none;
}
.right-table-box .left-col h5 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}
.right-table-box .left-col input {
    width: 27px;
    height: 35px;
    border-radius: 0;
    margin: 0 3px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    font-size: 13px;
}
.right-table-box .left-col .input-bundle-box{
    display: -webkit-flex;
    display: flex;
}
.right-table-box .left-col {
    width: 32%;
    border-right: 1px solid #23ef2c;
    padding-top: 62px;
}
.left-col-second{
    padding-top: 30px;
}
.left-col-large-col-from{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.left-col-large-col-from {
    -webkit-transform: rotate(-90deg) translate(-220px, -88px);
            transform: rotate(-90deg) translate(-220px, -88px);
    width: auto;
    left: -55px;
    position: absolute;
    height: 50px;
    display: -webkit-flex;
    display: flex;
}
.left-col-large-col-from .input-bundle-box-large input {
    width: 215px;
}
.left-col-large-col-from h5 {
    display: inline-block;
    width: 180px;
    padding-top: 10px;
}
.input-bundle-box-large{
    display: inline-block;
}
/*.right-table-box .left-col h5 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 180px;
}*/

.block-footer {
    display: -webkit-flex;
    display: flex;
    padding: 25px;
    -webkit-justify-content: center;
            justify-content: center;
}
.block-footer h4 {
    width: 50%;
    line-height: 20px;
    font-size: 14px;
}
.input-block-footer input{
    width: 60px;
    height: 60px;
    border-radius: 0;
    margin: 0 3px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    font-size: 13px;
}
.border-left {
    border-left: 1px solid #23ef2c !important;
}
.border-col-footer .box-col-list{
    padding: 20px;
}
.border-col-footer .box-col-list p{
    text-align: left;
    color: #23ef2c;
    font-weight: 600;
}
.footer-copy-right p {
    text-align: center;
    color: #23ef2c;
    font-weight: 600;
    padding-top: 17px;
}
.custom-examiner-col h5{
    display: inline-block;
}
.custom-examiner-col input {
    display: inline-block;
    width: 155px;
    border: none;
    border-bottom: 1px #23ef2c solid;
    border-radius: 0;
}
@charset "utf-8";

/* CSS Document */

@media (min-width: 1366px) and (max-width: 1900px) {





}

@media (min-width: 1200px) and (max-width: 1365px) {

	

}

@media (min-width: 1024px) and (max-width: 1250px){
.tm-header-navbar .navbar-nav.tm-header-list li a {
    padding: 8px 8px;
    font-size: 16px;
}
.service-content h3 {
    font-size: 20px;
}

}
@media (min-width: 1024px) and (max-width: 1190px){


}
@media (min-width: 768px) and (max-width: 1024px) {

.package-purchase-col form input {
    margin-bottom: 12px;
}
h1 {
    font-size: 30px;
    line-height: 40px;
}

}

@media only screen and (max-width: 991px){


}

@media only screen and (max-width: 767px) {
h1 {
    font-size: 30px;
    line-height: 34px;
}
h2 {
    font-size: 28px;
    line-height: 42px;
}
.from-col input {
    width: 30px;
    height: 30px;
    border-radius: 0;
    margin: 0 1px;
}

.from-col-list-data form input {
    border: none;
    border-bottom: 1px #ddd solid;
    width: 100%;
}
.right-table-box .left-col {
    width: 30%;
    padding: 20px;
    border-right: 1px solid #23ef2c;
    padding-top: 62px;
    text-align: center;
}
.right-col-table{
    width: 70%;
}
.border-col {
    margin: 10px 0;
}
.navbar-light .navbar-brand {
    color: rgba(0,0,0,.9);
    margin: 0 auto;
}
.border-col h4 {
    padding: 7px 15px;
    line-height: 22px;
}
.left-col-large-col-from {
    -webkit-transform: rotate(-90deg) translate(-220px, -70px);
            transform: rotate(-90deg) translate(-220px, -70px);
}

}

@media only screen and (max-width: 480px) {

.from-col input {
    width: 26px;
    height: 26px;
    margin: 0 0.5px;
}


}

@media only screen and (max-width: 420px) {

	
}

@media only screen and (max-width: 390px) {

}
.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}

.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border: 1px solid #92b0b3;
}

.files {
    position: relative
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.color input {
    background-color: #f1f1f1;
}

.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}
/*!

 =========================================================
 * Material Dashboard React - v1.6.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react-nodejs/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014   \A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  .badge-primary-background-color: #9c27b0;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  transform: translateY(-13%);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}

.fixed-plugin .github-btn {
  font: bold 11px/14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}

.fixed-plugin .gh-btn,
.fixed-plugin .gh-count,
.fixed-plugin .gh-ico {
  float: left;
}

.fixed-plugin .gh-btn,
.fixed-plugin .gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}

.fixed-plugin .gh-btn {
  background-color: #eee;
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}

.fixed-plugin .gh-btn:hover,
.fixed-plugin .gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}

.fixed-plugin .gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.fixed-plugin .gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.fixed-plugin .gh-count {
  position: relative;
  display: none;
  /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}

.fixed-plugin .gh-count:hover,
.fixed-plugin .gh-count:focus {
  color: #4183C4;
}

.fixed-plugin .gh-count:before,
.fixed-plugin .gh-count:after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.fixed-plugin .gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}

@charset "utf-8";

/* CSS Document */

@media (min-width: 1366px) and (max-width: 1900px) {





}

@media (min-width: 1200px) and (max-width: 1365px) {

	

}

@media (min-width: 1024px) and (max-width: 1250px){
.tm-header-navbar .navbar-nav.tm-header-list li a {
    padding: 8px 8px;
    font-size: 16px;
}
.service-content h3 {
    font-size: 20px;
}

}
@media (min-width: 1024px) and (max-width: 1190px){


}
@media (min-width: 768px) and (max-width: 1024px) {

.package-purchase-col form input {
    margin-bottom: 12px;
}
h1 {
    font-size: 30px;
    line-height: 40px;
}

}

@media only screen and (max-width: 991px){


}

@media only screen and (max-width: 767px) {
h1 {
    font-size: 30px;
    line-height: 34px;
}
h2 {
    font-size: 28px;
    line-height: 42px;
}
.from-col input {
    width: 30px;
    height: 30px;
    border-radius: 0;
    margin: 0 1px;
}

.from-col-list-data form input {
    border: none;
    border-bottom: 1px #ddd solid;
    width: 100%;
}
.right-table-box .left-col {
    width: 30%;
    padding: 20px;
    border-right: 1px solid #23ef2c;
    padding-top: 62px;
    text-align: center;
}
.right-col-table{
    width: 70%;
}
.border-col {
    margin: 10px 0;
}
.navbar-light .navbar-brand {
    color: rgba(0,0,0,.9);
    margin: 0 auto;
}
.border-col h4 {
    padding: 7px 15px;
    line-height: 22px;
}
.left-col-large-col-from {
    transform: rotate(-90deg) translate(-220px, -70px);
}

}

@media only screen and (max-width: 480px) {

.from-col input {
    width: 26px;
    height: 26px;
    margin: 0 0.5px;
}


}

@media only screen and (max-width: 420px) {

	
}

@media only screen and (max-width: 390px) {

}
/* Custom css */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Manuale:400,500,600,700');

/* font-family: 'Roboto', sans-serif;
   font-family: 'Manuale', serif; */


a:focus, a:hover{
	outline: none;
}
a{
	 -webkit-transition: all 0.5s ease-in-out;
       -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
	       -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
}
a:hover{
    text-decoration: none;
}
a, a:hover, a:focus, button, button:hover,  button:focus{
    outline: none;
    box-shadow: none;
}
.tm-h2 {
    font-size: 37px;
    line-height: 50px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    color: #771b17;
}


*,ul{
	margin: 0;
	padding: 0;
}
*,li{
	list-style: none;
}

body{	
	font-size: 14px;
	line-height: 24px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}
h1 {
    font-size: 52px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    color: #23ef2c;
    margin: 0;
    line-height: 60px;
}
h2 {
    font-size: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-transform: capitalize;
    color: #23ef2c;
    margin: 0;
    line-height: 50px;
}
h3 {
    font-size: 32px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #23ef2c;
    margin: 0;
    line-height: 24px;
}
h4 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #23ef2c;
    margin: 0;
    line-height: 40px;
}
h5 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #23ef2c;
    margin: 0;
    line-height: 40px;
}
h6 {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin: 0;
    line-height: 24px;
    color: #1d283f;
}
p {
    font-size: 15px;
    color: #7b7f86;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    font-weight: 400;
}

/* menu bar */

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-default .navbar-toggle {
    border-color: #258aff;
    background: #258aff;
    outline: none;
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.navbar-header{
	display:none;
}
.border-col {
    border: 1px #23ef2c solid;
    border-radius: 15px;
    margin-bottom: 10px;
    padding-bottom: 15px;
}
.border-col h4 {
    padding: 0px 15px;
    border-bottom: 1px #23ef2c solid;
    line-height: 32px;
}
.box-col h5{
    text-align: center;
}
.from-col input {
    width: 35px;
    height: 40px;
    border-radius: 0;
    margin: 0 3px;
    text-align: center;
}
.from-col label {
    margin: 0;
    color: #23ef2c;
    font-weight: 500;
    font-size: 16px;
    vertical-align: bottom;
}
.box-col {
    text-align: center;
    /*border-bottom: 1px #23ef2c solid;*/
}

.box-col p {
    padding: 0 20px;
    color: #23ef2c;
    text-align: left;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    border-bottom: 1px #23ef2c solid;
    padding-bottom: 10px;
    line-height: 20px;
}
.box-col-list h5 {
    text-align: left;
    padding: 0 5px;
}
.box-col-list h6 {
    text-align: left;
    padding: 0 20px;
    color: #23ef2c;
    font-weight: 500;
    padding-bottom: 5px;
    padding-top: 5px;
}
.box-col-list{
    text-align: left;
}
.box-col-list .from-col{
    padding: 0 20px;
}
.divider-form{
    color: #23ef2c;
    font-size: 50px;
    font-weight: 200;
}
.form-list-exam {
    width: 35%;
    display: inline-block;
}
.form-list-exam-2 {
    width: 22%;
    text-align: center;
}
.box-col-list {
    padding-top: 4px;
}
.box-col-list .from-col{
    padding-top: 10px;
}
.from-col-date{
    width: 100%;
}
.from-col-date input {
    width: 42%;
}
.from-col-date .time-col {
    width: 45px;
    height: 40px;
    margin: 0 3px;
    text-align: center;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    padding: 7px;
    color: #23ef2c;
    font-weight: 500;
    font-size: 16px;
}
.from-col-list-data input {
    width: 100%;
}
.form-row-inline-custom label {
    padding: 0;
    width: auto;
    display: inline-block;
}
.form-row-inline-custom  input {
    width: 35%;
    display: inline-block;
}
.phone-col-custom input {
    width: 65%;
}
.from-col-list-data form input {
    border: none;
    border-bottom: 1px #ddd solid;
}
.form-row-inline-custom{
    margin-bottom: 10px;
}
.form-row-inline-custom-1 input{
    width: 65%;
}
.textarea-text label{
    padding-bottom: 10px;
}
.note-text {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    display: inline-block;
    color: #23ef2c;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    padding-top: 10px;
}
.textarea-text{
    margin-bottom: 5px;
}
.border-col-1 h4 {
    padding: 0;
    font-size: 14px;
    text-align: center;
}
.box-width-samll{
    width: 30px;
}
.right-table-box .table td, .right-table-box .table th {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    vertical-align: middle;
    font-weight: 600;
    font-size: 15px;
}
.input-box-with-dot input {
    width: 35px;
    height: 35px;
    border-radius: 0;
    margin: 0 2px;
    text-align: center;
    display: inline-block;
}
.dot-from {
    width: 8px;
    height: 8px;
    background: #23ef2c;
    display: inline-block;
    border-radius: 50%;
}
.right-table-box .table thead tr th{
    color: #23ef2c;
}
.box-width-samll{
    text-align: center;
    color: #23ef2c;
}
.right-col-table table.table{
    margin-bottom: 0;
}
.border-col-1{
    padding-bottom: 0;
}
.right-col-table table.table td, .right-col-table table.table th {
    border-top: 1px solid #23ef2c;
}
.right-col-table table.table .border-right {
    border-right: 1px solid #23ef2c!important;
}

.right-table-box{
    display: flex;
}
.table thead th {
    border-bottom: none;
}
.right-table-box .left-col h5 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}
.right-table-box .left-col input {
    width: 27px;
    height: 35px;
    border-radius: 0;
    margin: 0 3px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    font-size: 13px;
}
.right-table-box .left-col .input-bundle-box{
    display: flex;
}
.right-table-box .left-col {
    width: 32%;
    border-right: 1px solid #23ef2c;
    padding-top: 62px;
}
.left-col-second{
    padding-top: 30px;
}
.left-col-large-col-from{
    transform: rotate(-90deg);
}
.left-col-large-col-from {
    transform: rotate(-90deg) translate(-220px, -88px);
    width: auto;
    left: -55px;
    position: absolute;
    height: 50px;
    display: flex;
}
.left-col-large-col-from .input-bundle-box-large input {
    width: 215px;
}
.left-col-large-col-from h5 {
    display: inline-block;
    width: 180px;
    padding-top: 10px;
}
.input-bundle-box-large{
    display: inline-block;
}
/*.right-table-box .left-col h5 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 180px;
}*/

.block-footer {
    display: flex;
    padding: 25px;
    justify-content: center;
}
.block-footer h4 {
    width: 50%;
    line-height: 20px;
    font-size: 14px;
}
.input-block-footer input{
    width: 60px;
    height: 60px;
    border-radius: 0;
    margin: 0 3px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    font-size: 13px;
}
.border-left {
    border-left: 1px solid #23ef2c !important;
}
.border-col-footer .box-col-list{
    padding: 20px;
}
.border-col-footer .box-col-list p{
    text-align: left;
    color: #23ef2c;
    font-weight: 600;
}
.footer-copy-right p {
    text-align: center;
    color: #23ef2c;
    font-weight: 600;
    padding-top: 17px;
}
.custom-examiner-col h5{
    display: inline-block;
}
.custom-examiner-col input {
    display: inline-block;
    width: 155px;
    border: none;
    border-bottom: 1px #23ef2c solid;
    border-radius: 0;
}